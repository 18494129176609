import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CWrapSection',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        name: {
            type: String,
            deflaut: ''
        }
    },
    computed: {
        id: function () {
            return this.$props.name ? this.$props.name + '-section' : '';
        }
    }
});
