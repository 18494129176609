import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        src: {
            type: String,
            default: '',
            require: true
        },
        srcSp: {
            type: String,
            default: '',
            require: false
        },
        alt: {
            type: String,
            default: '',
            require: false
        },
        loading: {
            type: String,
            default: 'lazy',
            validator: function (value) { return ['eager', 'lazy'].indexOf(value) >= 0; }
        }
    }
});
