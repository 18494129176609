import { render, staticRenderFns } from "./area-guide.vue?vue&type=template&id=5f736e59&scoped=true&"
import script from "./area-guide.vue?vue&type=script&lang=js&"
export * from "./area-guide.vue?vue&type=script&lang=js&"
import style0 from "./area-guide.vue?vue&type=style&index=0&id=5f736e59&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f736e59",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/section-title.vue').default,CommonCustomImage: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/custom-image.vue').default,CommonWrapSection: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/wrap-section.vue').default})
