import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        textEn: {
            type: String,
            default: ''
        },
        textJp: {
            type: String,
            default: ''
        }
    }
});
