import { render, staticRenderFns } from "./ticket.vue?vue&type=template&id=1791bbdb&scoped=true&"
var script = {}
import style0 from "./ticket.vue?vue&type=style&index=0&id=1791bbdb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1791bbdb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/section-title.vue').default,CommonWrapSection: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/wrap-section.vue').default})
