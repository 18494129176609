import { render, staticRenderFns } from "./about.vue?vue&type=template&id=24f89fd8&scoped=true&"
var script = {}
import style0 from "./about.vue?vue&type=style&index=0&id=24f89fd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f89fd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/section-title.vue').default,CommonCustomImage: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/custom-image.vue').default,TopAreaGuide: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/area-guide.vue').default,TopTicket: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/ticket.vue').default,TopAccess: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/access.vue').default})
